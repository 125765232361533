import * as React from "react"
import BaseLayout from "../components/layout"
import Seo from "../components/seo";
import { Link, graphql, useStaticQuery } from "gatsby";
import HorizontalRule from "../components/horizontal-rule";
import BreadcrumbTrail from "../components/breadcrumb-trail";
import kebabCase from "lodash/kebabCase";
import {SocialLinks} from "../../social-links";


const ContactPage = (props) => {
  const { site } = useStaticQuery(query);

  const aboutText = site.siteMetadata.aboutText
  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Contact',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Contact" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Contact/Follow</h1>
          </div>

          <div className="mt-20 grid grid-cols-2 md:grid-cols-4 sm:grid-cols-3">
            {SocialLinks.map((linkItem, index) => (
              <div key={index}>
                <h6 className="text-nav-header mt-10">{ linkItem.display }</h6>
                <h6 className="text-nav-item text-link flex align-middle"><a href={linkItem.url} target="_blank" rel="noreferrer noopener" aria-label={linkItem.display}>{linkItem.handle}</a></h6>
              </div>
            ))}
          </div>

        </div>
    </BaseLayout>
  )

}

export default ContactPage

const query = graphql`
  query Contact {
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
  }
`
